export const SET_BOOK = 'SET_BOOK';
export const SET_CURRENT_BOOK = 'SET_CURRENT_BOOK';

export function getBook(bookSlug) {
  return (dispatch) => {
    return fetch('/jsons/'+bookSlug+'.json').then(resp => resp.json()).then(response => {
        // A bit of data processing
      response[0].refTypes = {}
      response[0].references.forEach(reference => {
        if(response[0].refTypes[reference.genre]) {
          response[0].refTypes[reference.genre] += 1;
        } else {
          response[0].refTypes[reference.genre] = 1;
        }
      })
      dispatch(setBook(response));
     })
    .catch(error => console.log(error));
  }
}

export function setBook(data) {
  return {
    type : SET_BOOK,
    payload : data
  }
}

export function setCurrentBook(data) {
  return {
    type : SET_CURRENT_BOOK,
    payload : data
  }
}
