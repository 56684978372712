import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux';

import { getBook } from '../actions/actions_books';

import Header from './Header';
import Footer from './Footer';
import Home from '../pages/Home';
import About from '../pages/About';
import Book from '../pages/Book';
import Genre from '../pages/Genre';

function App({ dispatch }) {

  let location = useLocation();

  useEffect(() => {
    var booksToLoad = [
      'kafka-on-the-shore',
      'killing-commendatore',
      'the-wind-up-bird-chronicle',
      '1Q84',
      'colorless-tsukuru-tazaki',
      'men-without-women',
      'what-i-talk-about-when-i-talk-about-running',
      'pinball-1973',
      'hear-the-wind-sing'
    ];
    booksToLoad.forEach(bookSlug => dispatch(getBook(bookSlug)))
  }, [])

  return (
    <div className="main-site">
      <Header />
      <section className="section">
        <div className="container is-widescreen">
          <Switch location={location}>
            <Route path="/genre/:type" children={<Genre />} />
            <Route path="/book/:slug" children={<Book />} />
            <Route path="/about" children={<About />} />
            <Route path="/" children={<Home />} />
          </Switch>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default connect()(App);
