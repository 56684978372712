import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function BookTile({ book }) {
  return (
    <Link to={"/book/"+book.title.replace(/ /g, '-').toLowerCase()}>
      <div className={"column"}>
        <div className={"card"}>
          <div className="card-image">
            <figure className="image is-2by3">
              <img src={`./covers/${book.title}.jpg`} alt={book.title} />
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{book.title}</p>
              </div>
            </div>
            <div className="content">
              {book.references.length} total references.
              <div className="tag-refs tile-ref">
                {book.refTypes.writing ?
                  <span class="tag right-margin">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-book"></i>
                      </span>
                      <span>{book.refTypes.writing}</span>
                    </span>
                  </span>
                : false}
                {book.refTypes.philosophy ?
                  <span class="tag right-margin">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-brain"></i>
                      </span>
                      <span>{book.refTypes.philosophy}</span>
                    </span>
                  </span>
                : false}
                {book.refTypes.food ?
                  <span class="tag right-margin">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-cookie-bite"></i>
                      </span>
                      <span>{book.refTypes.food}</span>
                    </span>
                  </span>
                : false}
                {book.refTypes.music ?
                  <span class="tag right-margin">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-headphones"></i>
                      </span>
                      <span>{book.refTypes.music}</span>
                    </span>
                  </span>
                : false}
                {book.refTypes.painting ?
                  <span class="tag right-margin">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-paint-brush"></i>
                      </span>
                      <span>{book.refTypes.painting}</span>
                    </span>
                  </span>
                : false}
                {book.refTypes.film ?
                  <span class="tag right-margin">
                    <span class="icon-text">
                      <span class="icon">
                        <i class="fas fa-film"></i>
                      </span>
                      <span>{book.refTypes.film}</span>
                    </span>
                  </span>
                : false}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default BookTile;
