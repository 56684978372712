import React, { useState, useEffect } from 'react';

function About() {

  return (
    <>
      <div>
        <div class="block">The Murakamist is a site dedicated to expanding our range of cultural knowledge through the works of Murakami.</div>
        <div class="block">In general, I've tried to avoid repeated references in a single book, to keep things simple (for instance, in Kafka on the Shore,
          the Archduke Trio is mentioned many times but I only have it listed once).</div>
        <div class="block">More books will continue to be added in the future.</div>
        <div class="block">I hope you like the site!</div>
        <div class="block">- Victor Temprano (victor@mapster.me)</div>
      </div>
    </>
  );
}

export default About;
