import {
  SET_BOOK,
  SET_CURRENT_BOOK
} from '../actions/actions_books'

const books = (state = {
  allBooks : [],
  currentBook : false
}, action) => {
  switch (action.type) {
    case SET_BOOK:
      var existingBooks = JSON.parse(JSON.stringify(state.allBooks));
      var newBooks = existingBooks.concat(action.payload);
      return {
        ...state,
        allBooks : newBooks
      };
    case SET_CURRENT_BOOK:
      return {
        ...state,
        currentBook : action.payload
      };
    default:
      return state
  }
}

export default books
