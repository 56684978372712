import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

function Genre({ allBooks }) {

  let { type } = useParams();
  var referenceBooks = [];
  allBooks.forEach(book => {
    var hasGenre = false;
    var exists = book.references.find(ref => ref.genre === type)
    if(exists) {
      referenceBooks.push(book);
    }
  })
  if(referenceBooks.length > 0) {
    var fontAwesomeKey = {
      writing : "fa-book",
      music : "fa-headphones",
      food : "fa-cookie-bite",
      painting : "fa-paint-brush",
      film : "fa-film"
    }

    referenceBooks = referenceBooks.sort(() => Math.random() - 0.5)
    var toReturn = referenceBooks.map(book => {
      var theseReferences = book.references.filter(ref => ref.genre === type);
      return (
        <>
          <div className={"column expanded"}>
            <div className={"card expanded"}>
              <div className="card-image">
                <figure className="image is-2by3">
                  <img src={`/covers/${book.title}.jpg`} alt={book.title} />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">{book.title}</p>
                  </div>
                </div>
                <div className="content">
                  <div>
                    <div>
                      {theseReferences.map(reference => {
                        const icon = (
                          <span className="icon-text">
                            <span className="icon">
                              <i className={"fas "+fontAwesomeKey[reference.genre]}></i>
                            </span>
                          </span>
                        )
                        const link = (
                          <a href={`https://www.google.com/search?q=${reference.name} ${reference.work} ${reference.genre}`} target="_blank">
                            <span className="fas fa-external-link-alt"></span>
                          </a>
                        )
                        return (
                          <div class="block">
                            {reference.name!=="" && reference.work!=="" ?
                              <div>{icon} <div className="chapter">{reference.book}.{reference.chapter}</div> {reference.name + ', "' + reference.work + '"'} {link}</div>
                            : false}
                            {reference.name && reference.work==="" ? <div>{icon} <div className="chapter">{reference.book}.{reference.chapter}</div> {reference.name} {link}</div> : false}
                            {reference.name==="" && reference.work!=="" ? <div>{icon} <div className="chapter">{reference.book}.{reference.chapter}</div> "{reference.work}" {link}</div> : false}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    })
    return toReturn;
  } else {
    return false;
  }
}
const mapStateToProps = (state) => ({
  allBooks: state.books.allBooks,
});

export default connect(mapStateToProps)(Genre);
