import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

function Loading() {

  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ mobileMenu, setMobileMenu ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
      setInterval(() => {
        setIsLoaded(false)
        setTimeout(() => {
          setIsLoaded(true)
        }, 2640);
      }, 2640 * 4)
    }, 2640 * 2)
  }, [])

  return (
    <div className="content">
      <div className="is-hidden-touch">
        <Link to="/">
          <div className="loading-title-holder">
              <img className={"spin-cat " + (isLoaded ? "fadeout" : "")} src="/images/cat_spin_dribble_trimmed.gif" />
              <img className={"static-cat " + (isLoaded ? "fadein" : "")} src="/images/cat_spin_dribble_static.gif" />
              <div className="header-text">
                <h1>Murakamist</h1>
                <p><em>a collection of Haruki Murakami references</em></p>
              </div>
          </div>
        </Link>
        <div className="menu-links">
          <div><Link to="/genre/music">Music</Link></div>
          <div><Link to="/genre/writing">Writing</Link></div>
          <div><Link to="/genre/philosophy">Philosophy</Link></div>
          <div><Link to="/genre/food">Food</Link></div>
          <div><Link to="/genre/film">Film</Link></div>
          <div><Link to="/genre/painting">Painting</Link></div>
          <div>|</div>
          <div><Link to="/">By Book</Link></div>
        </div>
        <hr className="slash-line" />
      </div>
      <div className="is-hidden-desktop">
        <Link to="/">
          <div className="loading-title-holder">
            <div>
              <img className={"spin-cat " + (isLoaded ? "fadeout" : "")} src="/images/cat_spin_dribble_trimmed.gif" />
              <img className={"static-cat " + (isLoaded ? "fadein" : "")} src="/images/cat_spin_dribble_static.gif" />
            </div>
            <div className="header-text">
              <h1>Murakamist</h1>
            </div>
          </div>
        </Link>
        <span onClick={() => setMobileMenu(!mobileMenu)} className={`mobile-menu ${!mobileMenu ? 'fas fa-bars' : 'fas fa-times'}`} />
        {mobileMenu ?
          <div className="menu-links">
            <div><Link to="/genre/music" onClick={() => setMobileMenu(!mobileMenu)}>Music</Link></div>
            <div><Link to="/genre/writing" onClick={() => setMobileMenu(!mobileMenu)}>Writing</Link></div>
            <div><Link to="/genre/philosophy" onClick={() => setMobileMenu(!mobileMenu)}>Philosophy</Link></div>
            <div><Link to="/genre/food" onClick={() => setMobileMenu(!mobileMenu)}>Food</Link></div>
            <div><Link to="/genre/film" onClick={() => setMobileMenu(!mobileMenu)}>Film</Link></div>
            <div><Link to="/genre/painting" onClick={() => setMobileMenu(!mobileMenu)}>Painting</Link></div>
            <div>|</div>
            <div><Link to="/" onClick={() => setMobileMenu(!mobileMenu)}>By Book</Link></div>
          </div>
        : false}
      </div>
    </div>
  );
}

export default Loading;
