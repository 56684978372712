import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import BookTile from '../components/BookTile';

function Home({ allBooks }) {

  var randomBookSort = JSON.parse(JSON.stringify(allBooks)).sort(() => Math.random() - 0.5)
  console.log(randomBookSort)

  var leftOrRightExtra = 'left';
  return (
    <div>
      <div className="list-of-works">
        {randomBookSort.map((book, index) => {
          if((index+1)%2 === 0) { return false } else {
            if(leftOrRightExtra === 'left') {
              leftOrRightExtra = 'right'
            } else {
              leftOrRightExtra = 'left'
            }
            return (
              <div className="columns">
                {leftOrRightExtra === 'left' ?
                  <div className="column is-hidden-touch">
                  </div>
                : false}
                  <BookTile book={book} />
                {randomBookSort[index+1] ?
                  <BookTile book={randomBookSort[index+1]} />
                : false }
                {leftOrRightExtra === 'right' ?
                  <div className="column is-hidden-touch">
                  </div>
                : false}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  allBooks: state.books.allBooks,
});

export default connect(mapStateToProps)(Home);
