import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

function Footer() {

  return (
    <footer class="footer">
      <div class="content has-text-centered">
        <Link to="/about">About This Site</Link>
        <p>
          <strong>Murakamist</strong> is a site by <a href="http://victortemprano.com">Victor Temprano</a>.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
