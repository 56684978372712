import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

function Book({ allBooks }) {

  const [currentTab, setCurrentTab] = useState('all');

  let { slug } = useParams();
  const book = allBooks.find(book => book.title.replace(/ /g, '-').toLowerCase() === slug);
  if(book) {

    var fontAwesomeKey = {
      writing : "fa-book",
      music : "fa-headphones",
      food : "fa-cookie-bite",
      painting : "fa-paint-brush",
      film : "fa-film",
      philosophy : "fa-brain"
    }

    const hasMoreThanOneBook = book.references.find(ref => ref.book === '2');

    var referencesToLoop = [];
    var headerInsertions = {};
    if(currentTab === 'all') {
      referencesToLoop = book.references;
    }
    if(currentTab === 'chapter') {
      var chapDone = [];
      book.references.forEach((ref, index) => {
        var chapterLayout = hasMoreThanOneBook ? 'Book ' + ref.book + ', Chapter ' + ref.chapter : 'Chapter ' + ref.chapter;
        if(chapDone.indexOf(chapterLayout) === -1) {
          headerInsertions[index] = chapterLayout;
          chapDone.push(chapterLayout);
        }
      })
      referencesToLoop = book.references;
    }
    if(currentTab === 'genre') {
      var genreDone = [];
      var referencesCopy = JSON.parse(JSON.stringify(book.references));
      referencesToLoop = referencesCopy.sort((a, b) => a.genre > b.genre ? 1 : -1);
      referencesToLoop.forEach((ref, index) => {
        var genre = ref.genre;
        if(genreDone.indexOf(genre) === -1) {
          headerInsertions[index] = ref.genre[0].toUpperCase() + ref.genre.slice(1);
          genreDone.push(genre);
        }
      })
    }
    if(currentTab === 'alphabet') {
      var referencesCopy = JSON.parse(JSON.stringify(book.references));
      referencesToLoop = referencesCopy.sort((a, b) => {
        const comp1 = a.name !== "" ? a.name : a.work.replace(/"/g, "");
        const comp2 = b.name !== "" ? b.name : b.work.replace(/"/g, "");
        return comp1 > comp2 ? 1 : -1;
      })
    }

    return (
      <>
        <div className={"column expanded"}>
          <div className={"card expanded"}>
            <div className="card-image">
              <figure className="image is-2by3">
                <img src={`/covers/${book.title}.jpg`} alt={book.title} />
              </figure>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">{book.title}</p>
                </div>
              </div>
              <div className="content">
                <div className="tag-refs">
                  {book.refTypes.writing ?
                    <span className="tag right-margin">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-book"></i>
                        </span>
                        <span>{book.refTypes.writing}</span>
                      </span>
                    </span>
                  : false}
                  {book.refTypes.food ?
                    <span className="tag right-margin">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-cookie-bite"></i>
                        </span>
                        <span>{book.refTypes.food}</span>
                      </span>
                    </span>
                  : false}
                  {book.refTypes.philosophy ?
                    <span className="tag right-margin">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-brain"></i>
                        </span>
                        <span>{book.refTypes.philosophy}</span>
                      </span>
                    </span>
                  : false}
                  {book.refTypes.music ?
                    <span className="tag right-margin">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-headphones"></i>
                        </span>
                        <span>{book.refTypes.music}</span>
                      </span>
                    </span>
                  : false}
                  {book.refTypes.painting ?
                    <span className="tag right-margin">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-paint-brush"></i>
                        </span>
                        <span>{book.refTypes.painting}</span>
                      </span>
                    </span>
                  : false}
                  {book.refTypes.film ?
                    <span className="tag right-margin">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-film"></i>
                        </span>
                        <span>{book.refTypes.film}</span>
                      </span>
                    </span>
                  : false}
                </div>
                <div>
                  <div className="tabs">
                    <ul>
                      <li onClick={() => setCurrentTab('all')} className={currentTab==='all' ? "is-active" : ""}><a>In Order</a></li>
                      <li onClick={() => setCurrentTab('chapter')} className={currentTab==='chapter' ? "is-active" : ""}><a>By Chapter</a></li>
                      <li onClick={() => setCurrentTab('genre')} className={currentTab==='type' ? "is-active" : ""}><a>By Genre</a></li>
                      <li onClick={() => setCurrentTab('alphabet')} className={currentTab==='alphabet' ? "is-active" : ""}><a>Alphabetical</a></li>
                    </ul>
                  </div>
                  <div>
                    {referencesToLoop.map((reference, index) => {
                      const icon = (
                        <span className="icon-text">
                          <span className="icon">
                            <i className={"fas "+fontAwesomeKey[reference.genre]}></i>
                          </span>
                        </span>
                      )
                      const link = (
                        <a href={`https://www.google.com/search?q=${reference.name} ${reference.work} ${reference.genre}`} target="_blank">
                          <span className="fas fa-external-link-alt"></span>
                        </a>
                      )
                      return (
                        <div class="block">
                          {headerInsertions && headerInsertions[index] ? <h3>{headerInsertions[index]}</h3> : false}
                          {reference.name!=="" && reference.work!=="" ?
                            <div>{icon} <div className="chapter">{hasMoreThanOneBook ? reference.book + '.' : false}{reference.chapter}</div> {reference.name + ', "' + reference.work + '"'} {link}</div>
                          : false}
                          {reference.name && reference.work==="" ? <div>{icon} <div className="chapter">{hasMoreThanOneBook ? reference.book + '.' : false}{reference.chapter}</div> {reference.name} {link}</div> : false}
                          {reference.name==="" && reference.work!=="" ? <div>{icon} <div className="chapter">{hasMoreThanOneBook ? reference.book + '.' : false}{reference.chapter}</div> "{reference.work}" {link}</div> : false}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return false;
  }
}
const mapStateToProps = (state) => ({
  allBooks: state.books.allBooks,
});

export default connect(mapStateToProps)(Book);
